import React, { useEffect, useState } from 'react';
import { CheckboxWrapper, Line, WrapperInput } from './DeliveryAddressForm.parts';
import { Field, Form, Formik } from 'formik';
import { Input } from '../Input';
import { Button } from '../Button';
import { DeliverySchema } from './DeliveryAddressForm.validate';
import { EditBlock } from '../EditBlock';
import { setDeliveryInfo } from '../../store/user/user.reducers';
import { useDispatch, useSelector } from 'react-redux';
import { GroupRadioInput } from '../GroupRadioInput/GroupRadioInput';
import { StyledLabel } from '../Input/Input.parts';
import { selectorCart } from '../../store/cart/cart.selectors';
import { CheckboxFormik } from '../Checkbox/Checkbox';
import { setStep } from '../../store/view/view.reducers';
import { useScrollTop } from '../../hooks/useScrollTop';
import TagManager from 'react-gtm-module';
import { ReactGTMOptions } from '../../constants';
import ReactPixel from 'react-facebook-pixel';
import { checkIfUserExist, ErrorMessageType } from '../CreateAccountForm/CreateAccountForm.helpers';
import { selectorUserInfo } from '../../store/user/user.selectors';
import { ErrorMessage } from '../CreateAccountForm/CreateAccountForm.parts';
import { SIGN_IN_LINK } from '../CreateAccountForm/CreateAccountForm';

const initialValues = {
  firstName: '',
  lastName: '',
  phone: '',
  addressNickname: '',
  company: '',
  deliveryNote: '',
  marketing: '1',
  marketingEmail: true,
  marketingTxt: true,
  deliveryFee: false,
};

export const DeliveryAddressForm = () => {
  const [userValidation, setUserValidation] = useState<ErrorMessageType>();
  const [userValidationLoading, setUserValidationLoading] = useState<boolean>(false);

  const { address, costs } = useSelector(selectorCart);
  const { email } = useSelector(selectorUserInfo);

  const dispatch = useDispatch();

  useScrollTop();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: ReactGTMOptions({ title: 'Checkout: Delivery Address' }),
    });
    ReactPixel.track('ViewContent', { content_name: 'Checkout: Delivery Address' });
  }, []);

  return (
    <Formik
      initialValues={{ ...initialValues, deliveryFee: !address.is_rural_delivery }}
      onSubmit={async values => {
        setUserValidationLoading(true);
        setUserValidation({ error: '', status: 0 });

        const error = await checkIfUserExist({ email, phone: values.phone });

        setUserValidationLoading(false);

        if (error) {
          setUserValidation(error);
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          return;
        }
        dispatch(setStep(5));

        dispatch(
          setDeliveryInfo({
            address: address.address,
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phone,
            addressNickname: values.addressNickname,
            company: values.company,
            deliveryNote: values.deliveryNote,
            offers: Boolean(Number(values.marketing)),
            marketingEmail: values.marketingEmail,
            marketingTxt: values.marketingTxt,
          }),
        );
      }}
      validationSchema={DeliverySchema}
    >
      {props => {
        const { isValid, values, dirty, setFieldValue } = props;
        return (
          <>
            <Form>
              <Field type="text" id="firstName" name="firstName" label="First Name *" component={Input} />
              <Field type="text" id="last-name" name="lastName" label="Last Name *" component={Input} />
              <Field
                type="text"
                id="phone"
                name="phone"
                label="Phone *"
                userValidation={userValidation?.error}
                component={Input}
              />
              {userValidation?.error && (
                <ErrorMessage>
                  {userValidation?.error} {userValidation.status === 409 && SIGN_IN_LINK}
                </ErrorMessage>
              )}
              <Line />

              <EditBlock text={address.address} />

              <Field
                type="text"
                id="company"
                name="company"
                label="Company name"
                placeholder="Enter the Company name"
                component={Input}
              />

              <Field
                type="text"
                id="address-nickname"
                name="addressNickname"
                label="Address Nickname"
                placeholder="Enter the address name, e.g: My Home"
                component={Input}
              />

              <GroupRadioInput setValue={props.setFieldValue} />
              {!!address.is_rural_delivery && (
                <WrapperInput style={{ paddingBottom: 0 }}>
                  <CheckboxWrapper>
                    <Field
                      label="I understand that delivery to rural addresses may take longer than overnight, and Wonky Box cannot
                    provide refunds for produce affected by these extended delivery times. *"
                      id="deliveryFee"
                      name="deliveryFee"
                      type="checkbox"
                      labelColor="#787D2F"
                      component={CheckboxFormik}
                    />
                  </CheckboxWrapper>
                </WrapperInput>
              )}
              <Line />
              <WrapperInput>
                <StyledLabel>
                  Would you like to hear about our exclusive offers, tasty recipes, limited edition add-ons, food waste
                  news and the occasional free gift? *
                </StyledLabel>
                <CheckboxWrapper>
                  <Field
                    label="Yes please, I’d love that"
                    value="1"
                    id="marketing-1"
                    name="marketing"
                    type="radio"
                    labelColor="#787D2F"
                    component={CheckboxFormik}
                  />
                </CheckboxWrapper>
                <CheckboxWrapper>
                  <Field
                    label="No thank you"
                    value="0"
                    id="marketing-2"
                    name="marketing"
                    labelColor="#787D2F"
                    type="radio"
                    onClick={() => {
                      setFieldValue('marketingEmail', false);
                      setFieldValue('marketingTxt', false);
                    }}
                    component={CheckboxFormik}
                  />
                </CheckboxWrapper>
              </WrapperInput>
              {!!Number(values.marketing) && (
                <WrapperInput>
                  <StyledLabel>How would you like to hear from us?</StyledLabel>
                  <CheckboxWrapper>
                    <Field
                      label="Email"
                      id="marketing_email"
                      name="marketingEmail"
                      type="checkbox"
                      labelColor="#787D2F"
                      component={CheckboxFormik}
                    />
                  </CheckboxWrapper>
                  <CheckboxWrapper>
                    <Field
                      label="SMS"
                      id="marketing_txt"
                      name="marketingTxt"
                      type="checkbox"
                      labelColor="#787D2F"
                      component={CheckboxFormik}
                    />
                  </CheckboxWrapper>
                </WrapperInput>
              )}

              <Button type="submit" disabled={!(isValid && dirty) || userValidationLoading}>
                SAVE & CONTINUE
              </Button>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
